function modals() {
  // click button call me
  $('[data-modal=callme]').on('click', function () {
    $('.overlay, #callme').fadeIn(500);
  });

  $('.modal__close').on('click', function () {
    $('.overlay, #callme').fadeOut(500);
  })
}

module.exports = modals;
