// eslint-disable-next-line no-unused-vars
import checkProgImages from './lib/checkProgImages';
import scrollToTop from './lib/scrollToTop';
// import accordion from './lib/accordion';
import toggleMenu from './lib/mobileMenu';
import 'page-scroll-to-id';
import modals from './lib/modals'
import 'inputmask';
//! change fetch
import dispatchMessage from './lib/dispatchMessage';
import changeDefaultTextTooltip from './lib/changeDefaultTextTooltip';

toggleMenu('.menu', '.menu__btn');

scrollToTop();

modals();

dispatchMessage();

//  input mask
const input = document.querySelector('input[type="tel"]');
// eslint-disable-next-line no-undef
const im = new Inputmask('+375 (99) 999-99-99');
im.mask(input);

changeDefaultTextTooltip();

// scroll to block
$('.nav a').mPageScroll2id({
  scrollSpeed: 900,
  scrollEasing: 'easeInOutExpo',
  scrollingEasing: 'easeInOutCirc',
  clickedClass: 'active',
  keepHighlightUntilNext: !0
});
