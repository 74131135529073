function dispatchMessage() {
    $('form').submit(function(e) {
        e.preventDefault();
        $.ajax({
            type: 'POST',
            url: 'mailer/smart.php',
            data: $(this).serialize()
        }).done(function() {
            $(this).find('input').val('');

            $('.overlay, #callme').fadeOut(500);
            $('.overlay, #thanx').fadeIn(700);

            setTimeout(function() {
                    $('.overlay, #thanx').fadeOut(500);
            }, 5000);

            $('form').trigger('reset');
        });
        return false;
    });
}


module.exports = dispatchMessage;
